<template>
  <div>
    <CModal
        data-sel="mdl-add-ba"
        title="New Billing Article"
        :show.sync="showModal"
        size="lg"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <BillingArticleForm
          v-bind:billingArticleIn="billingArticle"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import BillingArticleForm from "@/apps/billing/components/BillingArticleForm";

const createBillingArticle = gql`
  mutation createBillingArticle($billingArticle: BillingArticleInput){
    createBillingArticle(billingArticle: $billingArticle) {
      nid
      name
      billomatId
    }
  }
`

export default {
  name: "BillingArticleAdd",
  components: {
    BillingArticleForm
  },
  data(){
    return {
      billingArticle: {},
      billingArticleSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.billingArticleSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createBillingArticle,
          variables: {
            billingArticle: this.billingArticleSave
          }
        })
        .then(res => {
          this.$emit('add-item', res.data.createBillingArticle);
          this.billingArticle = {};
          this.billingArticleSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.billingArticle = {};
        this.billingArticleSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
